import React from 'react';
import { Link } from 'gatsby';

import useCountriesData from "../../hooks/hook.useCountriesData";
import Button from '../component.button';

const ContactFormFields = ({ contactDetails, handleInputChange }) => {

    const countries = useCountriesData();

    return (

        <div className="c-product-contact__form">
            <div className="c-product-contact__option-title">
                Contact Form
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="c-input-holder">
                        <div className="c-input-holder__select__wrapper">
                            <select
                                className="c-input-holder__select"
                                type="text"
                                id="contactCountry"
                                name="country"
                                value={contactDetails.country}
                                onChange={handleInputChange}
                                required="true"
                            >
                                <option value=''>COUNTRY</option>
                                {countries.map((country, index) => (
                                    <option key={index} value={country.ACFDealerBlockFields.country}>{country.ACFDealerBlockFields.country}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="c-input-holder">
                        <input
                            className={contactDetails.phoneNumber.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                            type="number"
                            id="contactPhoneNumber"
                            name="phoneNumber"
                            value={contactDetails.phoneNumber}
                            onChange={handleInputChange}
                            required
                        />
                        <label className="c-input-holder__label" htmlFor="contactPhoneNumber">Phone number</label>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="c-input-holder">
                        <input
                            className={contactDetails.contactName.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                            type="text"
                            id="contactName"
                            name="contactName"
                            value={contactDetails.contactName}
                            onChange={handleInputChange}
                        />
                        <label className="c-input-holder__label" htmlFor="contactName">Name <span>(optional)</span></label>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="c-input-holder">
                        <input
                            className={contactDetails.club.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                            type="text"
                            id="contactClub"
                            name="club"
                            value={contactDetails.club}
                            onChange={handleInputChange}
                        />
                        <label className="c-input-holder__label" htmlFor="contactClub">Club <span>(optional)</span></label>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="c-input-holder">
                        <input
                            className={contactDetails.email.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                            type="text"
                            id="contactEmail"
                            name="email"
                            value={contactDetails.email}
                            onChange={handleInputChange}
                            required
                        />
                        <label className="c-input-holder__label" htmlFor="contactEmail">Email</label>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="c-input-holder">
                        <input
                            className={contactDetails.weight.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                            type="text"
                            id="contactWeight"
                            name="weight"
                            value={contactDetails.weight}
                            onChange={handleInputChange}
                        />
                        <label className="c-input-holder__label" htmlFor="contactWeight">Athlete/crew average weight <span>(optional)</span></label>
                    </div>
                </div>

                <div className="col-12">
                    <div className="c-input-holder">
                        <textarea
                            className={contactDetails.message.length ? "c-input-holder__textarea c-input-holder__textarea--has-content" : "c-input-holder__textarea"}
                            type="text"
                            id="message"
                            name="message"
                            value={contactDetails.message}
                            onChange={handleInputChange}
                        />
                        <label className="c-input-holder__label" htmlFor="message">Message <span>(optional)</span></label>
                    </div>
                </div>
            </div>

            <div className="c-product-contact__call">
                <div>
                    <span className="c-product-contact__talk">
                        <i className="fal fa-phone"></i> Prefer to talk?
                    </span>
                    <Link className="c-product-contact__phone" to="/distributors/">
                        Call your local distributor
                    </Link>
                </div>

                <Button text="Send Enquiry" />
            </div>

        </div>
    );
};

export default ContactFormFields;
