import React, { useState } from 'react';
import { Link } from 'gatsby';

const WeightDimensions = ({ weightOptions, lengthOptions }) => {
    const [weightType, toggleWeightType] = useState('metric');

    return (
        <>
            <ul className="c-toggle">
                <li 
                    onClick={() => toggleWeightType('imperial')}
                    className={weightType === 'imperial' ? 'c-toggle__item c-toggle__item--active' : 'c-toggle__item'}
                >
                    Imperial
                </li>
                <li
                    onClick={() => toggleWeightType('metric')}
                    className={weightType === 'metric' ? 'c-toggle__item c-toggle__item--active' : 'c-toggle__item'}
                >
                    Metric
                </li>
            </ul>
    
            {weightOptions && weightOptions.map(({ title, row }, index) => (
                <div className="c-table" key={index}>
                    <div className="c-table__head">
                        <div className="c-table__col c-table__col--third c-table__col--border-bottom c-table__col--border-right">{title}</div>
                        <div className="c-table__col c-table__col--third c-table__col--border-bottom c-table__col--border-right">Crew Weight Range</div>
                        <div className="c-table__col c-table__col--third c-table__col--border-bottom">Average Athlete Weight</div>
                    </div>
                    {row && row.map(({ crewWeightRangeKg, crewWeightRangeLbs, averageAthleteWeightKg, averageAthleteWeightLbs, label }, index) => (
                        <div className="c-table__row" key={index}>
                            <div className="c-table__col c-table__col--third c-table__col--border-bottom c-table__col--border-right">{label}</div>

                            {weightType === 'imperial' && (
                                <>
                                    <div className="c-table__col c-table__col--third c-table__col--border-bottom c-table__col--border-right">{crewWeightRangeLbs && `${crewWeightRangeLbs} lbs`}</div>
                                    <div className="c-table__col c-table__col--third c-table__col--border-bottom">{averageAthleteWeightLbs && `${averageAthleteWeightLbs} lbs`}</div>
                                </>
                            )}

                            {weightType === 'metric' && (
                                <>
                                    <div className="c-table__col c-table__col--third c-table__col--border-bottom c-table__col--border-right">{crewWeightRangeKg && `${crewWeightRangeKg} kg`}</div>
                                    <div className="c-table__col c-table__col--third c-table__col--border-bottom">{averageAthleteWeightKg && `${averageAthleteWeightKg} kg`}</div>
                                </>
                            )}                            
                        </div>
                    ))}
                </div>
            ))}
            
            <span className="d-block xs-mt-30 xs-mb-10">
                For detailed specifications on each model please refer to our <Link className="u-text--link" to="/technology/specifications/">specifications chart</Link>.
            </span>
    
            {/* Length details hidden for launch, to be added back after launch */}
            {/* {lengthOptions && lengthOptions.map((option, index) => (
                <div className="c-table" key={index}>
                    <div className="c-table__row">
                        <div className="c-table__col c-table__col--border">
                            <span className="c-table__heading">
                                {option.class[0].name}
                            </span>
                        </div>
                    </div>
    
                    <div className="c-table__row">
                        <div className="c-table__col c-table__col--half c-table__col--border-bottom c-table__col--border-right">Hull Width</div>
                        <div className="c-table__col c-table__col--half c-table__col--border-bottom">{option.hullWidth}</div>
                    </div>
                    
                    <div className="c-table__row">
                        <div className="c-table__col c-table__col--half c-table__col--border-bottom c-table__col--border-right">Hull Length</div>
                        <div className="c-table__col c-table__col--half c-table__col--border-bottom">{option.length}</div>
                    </div>
                </div>
            ))} */}
        </>
    );
};

export default WeightDimensions;
