import React, { useState, useContext, useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import Info from '../components/component.info';

import ThemeContext from "../context/context.theme";
import ProductInfoBanner from '../components/product/component.product-info-banner';
import ProductDetails from '../components/product/component.product-details';
import ProductEnquiry from '../components/product/component.product-enquiry';
import ProductRelated from '../components/product/component.product-related';
import ProductSpec from '../components/product/component.product-spec';
import ProductHero from '../components/product/component.product-hero';
import ProductClasses from '../components/product/component.product-classes';
import ProductTitle from '../components/product/component.product-title';
import CookieNotice from "../components/component.cookie-notice";

const ProductTemplate = ({ data, location }) => {
    const theme = useContext(ThemeContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const product = data.wordpress.product;
    const seo = data.wordpress.product.seo;
    const productDetails = data.wordpress.product.ACFProductBlockFields;

    let productInitialActiveClass = (product.ACFProductBlockFields.classesInformation ? product.ACFProductBlockFields.classesInformation[0].class.name : null);

    const [activeTab, setActiveTab] = useState(productInitialActiveClass);
    const [minTabHeight, setminTabHeight] = useState(0);

    useEffect(() => {
        if(location.state) {
            if(location.state.activeClass) {
                productInitialActiveClass = location.state.activeClass;
            }
        }
        if (typeof window !== 'undefined' && window) {
            const queryString = new URLSearchParams(location.search);
            const thisBoatClass = queryString.get("class");
            if(thisBoatClass !== null) {
                // validate it:
                let i, thisClass;
                for(i in product.ACFProductBlockFields.classesInformation) {
                    thisClass = product.ACFProductBlockFields.classesInformation[i].class.name;
                    if (thisClass.toLowerCase() == thisBoatClass.toLowerCase()) {
                        productInitialActiveClass = thisClass;
                        break;
                    } else if (thisClass.toLowerCase() == thisBoatClass.replace("-","/").toLowerCase()){
                        // allow Pair-Double instead of Pair%2FDouble so it's more human readable
                        productInitialActiveClass = thisClass;
                        break;
                    }
                }
            }
        }
        setActiveTab(productInitialActiveClass);
    }, []);

    return (
        <div>
            <Layout>
                <SEO title={product.title} seo={seo} image={product.featuredImage} />

                <ProductInfoBanner title={product.title} setModalIsOpen={setModalIsOpen} classInfo={product.ACFProductBlockFields.classesInformation} />

                {productDetails.images &&
                    <div className={theme.screenType === 'desktop' ? "container" : ""}>
                        <ProductHero images={productDetails.images} activeClass={activeTab} />
                    </div>
                }

                <div className="container">
                    <ProductTitle brandName={productDetails.brandName} shortDescription={productDetails.shortDescription} brandLogo={productDetails.brandLogo} />
                    
                    {product.ACFProductBlockFields.classesInformation &&
                        <div className="c-tabs">
                            <ol className="c-tabs__list">
                                {product.ACFProductBlockFields.classesInformation && product.ACFProductBlockFields.classesInformation.map((classInfo, index) => {
                                    const name = classInfo.class.name;
                                    return (
                                        <li key={index} className={activeTab === name ? "c-tabs__item c-tabs__item--active" : "c-tabs__item"} key={name} onClick={() => {
                                            setActiveTab(name);
                                            setminTabHeight(tabContent.current.clientHeight);
                                        }}>
                                            {name}
                                        </li>
                                    );
                                })}
                            </ol>
                            <ProductClasses
                                classes={product.ACFProductBlockFields.classesInformation}
                                activeTab={activeTab}
                                minTabHeight={minTabHeight}
                            />
                        </div>
                    }

                </div>

                <ProductEnquiry
                    productName={product.title}
                    comesWith={productDetails.comesWith}
                    whoShouldBuyIt={productDetails.whoShouldBuyIt}
                    classInfo={product.ACFProductBlockFields.classesInformation}
                    activeClass={activeTab}
                    brandName={productDetails.brandName}
                    brandLogo={productDetails.brandLogo}
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    productType={productDetails.productType}
                />

                <div className="container">
                    {productDetails.tabContent &&
                        <ProductDetails content={productDetails.tabContent} />
                    }
                    <ProductSpec
                        productType={productDetails.productType}
                        weightOptions={productDetails.weightSpecification}
                        lengthOptions={productDetails.lengthSpecification}
                        riggerOptions={productDetails.riggerOptions}
                        shoeOptions={productDetails.shoeOptions}
                    />
                </div>

                {productDetails.relatedProducts &&
                    <ProductRelated products={productDetails.relatedProducts} />
                }

                <Info />
                <CookieNotice />
            </Layout>
        </div>
    );
};

export const query = graphql`
    query($id: ID!) {
        wordpress {
            product(id: $id) {
                title
                seo {
                    ...SeoPostFields
                }
                featuredImage {
                    node {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1140) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
                ACFProductBlockFields {
                    productType
                    tabContent {
                        content
                        title
                        image {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 550) {
                                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                    whoShouldBuyIt
                    shortDescription
                    descriptionExcerpt
                    brandName
                    brandLogo {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 70) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                    comesWith {
                        item
                    }
                    classesInformation {
                        weight
                        stiffness
                        stability
                        durability
                        class {
                            ... on WORDPRESS_Class {
                                name
                                id
                            }
                        }
                        availableHullShapes {
                            ...on WORDPRESS_HullShape {
                                title
                                # content
                                ACFHullShapeBlockFields {
                                    bestFor
                                    description
                                }
                                featuredImage {
                                    node {
                                        altText
                                        sourceUrl
                                        imageFile {
                                            childImageSharp {
                                                fixed(width: 100, height: 100) {
                                                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        sliderImages {
                            image {
                                altText
                                sourceUrl
                                imageFile {
                                    childImageSharp {
                                        fluid(maxWidth: 2340) {
                                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                            presentationWidth
                                        }
                                    }
                                }
                            }
                        }
                    }
                    images {
                        image {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 2340) {
                                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                        presentationWidth
                                    }
                                }
                            }
                        }
                        imageClass {
                          name
                        }
                    }
                    weightSpecification {
                        title
                        row {
                            label
                            crewWeightRangeKg
                            crewWeightRangeLbs
                            averageAthleteWeightKg
                            averageAthleteWeightLbs
                        }
                    }
                    lengthSpecification {
                        class {
                            ...on WORDPRESS_Class {
                                name
                            }
                        }
                        hullWidth
                        length
                    }
                    riggerOptions {
                        name
                        image {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 2340) {
                                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                    shoeOptions {
                        name
                        image {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 2340) {
                                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                    relatedProducts {
                        ... on WORDPRESS_Product {
                            ACFProductBlockFields {
                                productType
                                classesInformation {
                                    class {
                                        ... on WORDPRESS_Class {
                                            name
                                        }
                                    }
                                }
                                brandLogo {
                                    altText
                                    sourceUrl
                                    imageFile {
                                        childImageSharp {
                                            fixed(width: 40) {
                                                ...GatsbyImageSharpFixed_withWebp_tracedSVG
                                            }
                                        }
                                    }
                                }
                                whoShouldBuyIt
                                price
                                skill
                                shortDescription
                                bestSeller
                                racesWon {
                                    race
                                }
                                mainImage {
                                    altText
                                    sourceUrl
                                    imageFile {
                                        childImageSharp {
                                            fluid(maxWidth: 1580) {
                                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                            }
                                        }
                                    }
                                }
                            }
                            classes {
                                nodes {
                                name
                                id
                                }
                            }
                            levels {
                                nodes {
                                id
                                name
                                }
                            }
                            slug
                            title
                        }
                    }
                }
            }
        }
    }
`;

export default ProductTemplate;
