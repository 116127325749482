import React from 'react';
import { graphql, useStaticQuery } from "gatsby";

const DeliveryFinancing = () => {
    const data = useStaticQuery(graphql`
        query Content {
            wordpress {
                globalContent(id: "Z2xvYmFsX2NvbnRlbnQ6MjM3") {
                    ACFDeliveryAndFinanceBlockFields {
                      content
                    }
                }
            }
        }
    `);
    
    const content = data.wordpress.globalContent.ACFDeliveryAndFinanceBlockFields.content;

    return (
        <>
            {content && 
                <div className="o-content" dangerouslySetInnerHTML={{ __html: content }}></div>
            }
        </>
    );
}

export default DeliveryFinancing;
