import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import NonStretchedImage from './component.non-stretched-image';
import { getStatus } from '../utilties/helpers';
import Button from '../components/component.button';

const ProductOverview = ({ details }) => {
    const { title, slug, ACFProductBlockFields: acf } = details;
    const classes = acf.classesInformation;
    const levels = details.levels.nodes;
    const price = acf.productType === 'boat' ? getStatus({ full: 5, amount: acf.price, title: 'Price' }): null;
    const skill = acf.productType === 'boat' ? getStatus({ full: 5, amount: acf.skill, title: 'Skill' }): null;
    
    return (
        <article className="c-product">
            <div className="c-product__details">
                {acf.bestSeller && (
                    <div className="c-tag d-none d-lg-flex">
                        <span className="c-tag__title">#1 Bestseller</span>
                        <span className="c-tag__banner">
                            <i className="c-tag__icon fal fa-trophy"></i>
                        </span>
                    </div>
                )}

                {acf.productType === 'boat' && levels && levels.map((level, index) => (
                    <span className="c-product__tag" key={index}>{`Racing: ${level.name}`}</span>
                ))}

                <div className="row">
                    {acf.mainImage && acf.mainImage.imageFile &&
                        <div className="col-lg-7 order-lg-2">
                            <div className="c-product__image">
                                <Link to={`/${slug}/`}>
                                    <NonStretchedImage fluid={acf.mainImage.imageFile.childImageSharp.fluid} alt={acf.mainImage.sourceUrl} />
                                </Link>
                            </div>
                        </div>
                    }

                    <div className="col-lg-5 order-lg-1">
                        <div className="c-product__title-wrapper">
                            {acf.brandLogo && (process.env.GATSBY_THEME != "King") && (
                                <Img className="c-product__logo" fixed={acf.brandLogo.imageFile.childImageSharp.fixed} alt={acf.brandLogo.altText} />
                            )}

                            {title &&
                                <Link className="c-product__title-link" to={`/${slug}/`}>
                                    <h1 className="c-product__title" dangerouslySetInnerHTML={{ __html: title }}></h1>
                                </Link>
                            }

                            {acf.brandLogo && (process.env.GATSBY_THEME == "King") && (
                                <Img className="c-product__logo c-product__logo__right" fixed={acf.brandLogo.imageFile.childImageSharp.fixed} alt={acf.brandLogo.altText} />
                            )}

                        </div>
                        
                        {acf.descriptionExcerpt &&
                            <div className="c-product__desc">
                                {acf.descriptionExcerpt}
                            </div>
                        }
                        
                        {classes &&
                            <ul className="c-product__class-list">
                                <li className="c-product__class-item">Class:</li>
                                {classes.map((boatClass, index) => {
                                    const { name } = boatClass.class;
                                    return (
                                        <li key={index} className="c-product__class-item">
                                            <Link
                                                className="c-product__class-link"
                                                to={`/${slug}/`}
                                                state={{ activeClass: name }}
                                            >
                                                {name}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        }

                        <Button link rel={acf.bestSeller ? "prefetch" : null} className="c-product__button" to={`/${slug}/`} aria-label={`Learn more about ${title}`} text="Learn more" />
                    </div>
                </div>
            </div>

            {(skill || price || acf.whoShouldBuyIt || acf.racesWon) &&
                <div className="c-product__more u-bg--waved-texture">
                    <div className="row">
                        {acf.productType === 'boat' && (skill || price) &&
                            <div className="col-lg-3">
                                <ul className="c-product__stats-list">
                                    {skill &&
                                        <li className="c-product__stats-item">
                                            <h2 className="c-product__section-title c-product__section-title--alt">Skill to row</h2>
                                            {skill}
                                            </li>
                                    }
                                    {price &&
                                        <li className="c-product__stats-item">
                                            <h2 className="c-product__section-title c-product__section-title--alt">Price</h2>
                                            {price}
                                        </li>
                                    }
                                </ul>
                            </div>
                        }
                        
                        {acf.whoShouldBuyIt &&
                            <div className="col-lg-6 d-none d-lg-block">
                                <h2 className="c-product__section-title">Who should buy it and why it's great.</h2>
                                <span className="c-product__overview" dangerouslySetInnerHTML={{ __html: acf.whoShouldBuyIt }}></span>
                            </div>
                        }

                        {acf.racesWon &&
                            <div className="col-lg-3 d-none d-lg-block">
                                <h2 className="c-product__section-title">Races won</h2>
                                <ul className="c-product__list">
                                    {acf.racesWon && acf.racesWon.map((race, index) => <li key={index} className="c-product__list-item">{race.race}</li>)}
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            }
        </article>
    );
}

export default ProductOverview;
