import React from 'react';

import ProductOverview from '../component.product-overview';

const ProductsList = ({ products }) => (
    <ul className="c-products-list">
        {(products.length > 0) && products.map((product, index) => (
            <li key={index} className="c-products-list__item">
                <ProductOverview details={product} />
            </li>
        ))}

        {(products.length === 0) && (
            <li className="xs-mb-40">There are no products that match your filter.</li>
        )}
    </ul>
);

export default ProductsList;