import React from 'react';

import Button from '../component.button';

const ProductInfoBanner = ({ title, setModalIsOpen, classInfo }) => (
    <div className="c-product-info-banner u-bg--pickled-bluewood">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="c-product-info-banner__content">
                        <h1 className="c-product-info-banner__title" dangerouslySetInnerHTML={{ __html: title }}></h1>
                        <Button outlineAllWhite autoWidth text="Contact us for pricing" onClick={() => setModalIsOpen(true)} />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ProductInfoBanner;
