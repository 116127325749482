import React from 'react';

import ProductsList from '../plp/component.products-list';

const ProductRelated = ({ products }) => (
    <div className="u-bg--wild-sand xs-pt-20 md-pt-40">
        <div className="container">
            <div className="row">
                <div className="col">
                    <h2 className="u-text--center md-pb-30">Also consider&hellip;</h2>
                    <ProductsList products={products} />
                </div>
            </div>
        </div>
    </div>
);

export default ProductRelated;