import React from 'react';
import {graphql, useStaticQuery} from "gatsby";

const Faqs = () => {
    
    const data = useStaticQuery(graphql`
        query Faqs {
            wordpress {
                globalContent(idType: SLUG, id: "faqs") {
                    ACFFaqsBlockFields {
                        faqs {
                            answer
                            question
                        }
                    }
                }
            }
        }
    `);
    
    const faqs = data.wordpress.globalContent.ACFFaqsBlockFields.faqs;
    
    return (
        <div className="c-faqs">
            {faqs.map((faq, index) => (
                <div key={index}>
                    <div className="c-faqs__question">Q: {faq.question}</div>
                    <div className="o-content" dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                </div>
            ))}
        </div>
    )
}

export default Faqs;
