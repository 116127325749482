import React from 'react';
import {graphql, useStaticQuery} from "gatsby";

const Testimonials = () => {
    
    const data = useStaticQuery(graphql`
        query Testimonials {
            wordpress {
                globalContent(idType: SLUG, id: "testimonials") {
                    ACFTestimonialBlockFields {
                        testimonials {
                            testimonial
                            testimonialTitle
                            company
                            author
                        }
                    }
                }
            }
        }
    `);
    
    const testimonials = data.wordpress.globalContent.ACFTestimonialBlockFields.testimonials;
    return (
        <div className="container-fluid">
            <div className="c-testimonial-list">
                {testimonials && testimonials.map((testimonial, index) => (
                    <div key={index} className="row">
                        <div className="col-md-4">
                            <div className="c-testimonial-list__author">
                                <div>Club: {testimonial.company}</div>
                                <div>{testimonial.author}</div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="c-testimonial-list__title">{testimonial.testimonialTitle}</div>
                            <div className="o-content" dangerouslySetInnerHTML={{ __html: testimonial.testimonial }}></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Testimonials;
